<template>
  <div class="settings-content-wrapper">
    <div >
      <h1>Business Activity Statement</h1>
      <hr>
      <b-field class="modal-label" label="Reporting Period">
        <b-select placeholder="Select reporting period" v-model="reportingPeriodId">
          <option
            v-for="reportingPeriod in basReportingPeriods"
            :value="reportingPeriod.id"
            :key="reportingPeriod.id">
            {{ reportingPeriod.name }}
          </option>
        </b-select>
      </b-field>
      <b-button class="is-success" @click="updateReport">Update</b-button>
      <hr>
      <div class="box">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total Income</p>
              <p class="title">{{ bas.totalIncome | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total G.S.T. On Income</p>
              <p class="title">{{ bas.totalGSTOnIncome | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total Expenses</p>
              <p class="title">{{ bas.totalExpenses | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total G.S.T. On Expenses</p>
              <p class="title">{{ bas.totalGSTOnExpenses | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Amount You Owe The Tax Office</p>
              <p class="title">{{ bas.amountYouOweTaxOffice | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Amount The Tax Office Owes You</p>
              <p class="title">{{ bas.amountTaxOfficeOwesYou | basReportFigure }}<span class="text-muted">.00</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style  lang="scss" scoped>

  .heading, .title {
    display: block;
  }

  .text-muted {
    opacity: 0.4;
  }

</style>

<script>
export default {
  name: 'BusinessActivityStatement',
  computed: {
    bas () {
      return this.$store.getters['reports/bas']
    },
    basReportingPeriods () {
      return this.$store.getters['reports/basReportingPeriods']
    }
  },
  data () {
    return {
      reportingPeriodId: 202010
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'reports-home' })
    },
    updateReport () {
      this.$store.dispatch('reports/fetchBas', this.reportingPeriodId)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Report updated.',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            duration: 4000
          })
        })
    }
  },
  mounted () {
    this.$store.dispatch('reports/fetchBasReportingPeriods')
      .then((response) => {
        console.log('fetched bas reporting periods', response.data)
        if (response.data.length > 0) {
          this.reportingPeriodId = response.data[0].id
          this.updateReport()
        }
      })
  }
}
</script>
